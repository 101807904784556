<template>
  <div class="contatain">
    <div class="flex-row">
      <el-input
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.searchValue"
      />
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        :loading="loading"
        @click="getDictDataDataList"
        >搜索</el-button
      >
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="openDialog('addDictData')"
        >添加</el-button
      >
    </div>
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="dictDataList"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          max-height="650"
          v-loading="loading"
        >
          <!-- <el-table-column prop="id" label="ID" width="180"> </el-table-column> -->
          <el-table-column
            prop="dictLabel"
            label="字典标签"
            width="280"
          ></el-table-column>
          <el-table-column prop="dictValue" label="字典键值" width="100">
          </el-table-column>
          <el-table-column prop="dictNo" label="字典编号" width="100">
          </el-table-column>
          <el-table-column prop="dictSort" label="排序" width="60">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="230">
            <template slot-scope="scope">
              {{ transTime(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="openDialog('addDictData', scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteDictData(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="count"
          :page-size="params.limit"
          :current-page="params.page"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </el-card>
    </div>
    <add-dict-data
      ref="addDictData"
      @getDictDataDataList="getDictDataDataList"
      :editForm="editForm"
      :type="params.type"
    />
  </div>
</template>
<script>
import { debounce, timestampToDate } from "@tools";
import addDictData from "./common/components/addDictData";
export default {
  components: {
    addDictData,
  },
  data() {
    return {
      loading: false,
      dictDataList: [],
      params: {
        limit: 10,
        page: 1,
        searchValue: "",
        type: "",
      },
      count: 0,
      editForm: null,
    };
  },
  async created() {
    this.params.type = this.$route.query.type;
    this.getDictDataDataList();
  },
  mounted() {},
  methods: {
    getDictDataDataList: debounce(async function () {
      this.loading = true;
      const data = await this.$api.systemConfig
        .getDictData(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.dictDataList = data.data;
      this.count = data.count;
    }, 600),
    handleSizeChange(size) {
      this.params.limit = size;
      this.getDictDataDataList();
    },
    handlePageChange(page) {
      this.params.page = page;
      this.getDictDataDataList();
    },
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      if (form) {
        const {
        id,
        dictLabel,
        dictType,
        dictSort,
        dictValue,
        isDefault,
        remark,
        listClass,
        dictNo
      } = form;
        this.editForm = {
        id,
        dictLabel,
        dictType,
        dictSort,
        dictValue,
        isDefault,
        remark,
        listClass,
        dictNo
      };
      } else {
        this.editForm = null;
      }
      this.$refs[ref].showDialog = true;
    },
    deleteDictData(id) {
      this.$confirm("此操作将永久删除该字典数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.systemConfig.deleteDictData(id);
          this.getDictDataDataList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toDictData(type) {
      this.$router.push({ path: "/home/dictData", query: { type } });
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
</style>