<template>
  <el-dialog width="25%" :title="title" :visible.sync="showDialog">
    <el-form :model="form" label-width="80px">
      <el-form-item label="字典标签">
        <el-input v-model="form.dictLabel"></el-input>
      </el-form-item>
      <el-form-item label="字典键值">
        <el-input v-model="form.dictValue"></el-input>
      </el-form-item>
      <el-form-item label="字典编号">
        <el-input v-model="form.dictNo"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="form.dictSort"></el-input>
      </el-form-item>
      <el-form-item label="样式">
        <el-input v-model="form.listClass"></el-input>
      </el-form-item>
      <el-form-item label="默认">
        <el-radio-group v-model="form.isDefault">
          <el-radio label="Y">否</el-radio>
          <el-radio label="N">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="showDialog = false">取 消</el-button>
      <el-button
        size="mini"
        type="primary"
        @click="editDictData"
        :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "addDictData",
  props: ["editForm", 'type'],
  data() {
    return {
      loading: false,
      showDialog: false,
      form: {
        id: "",
        dictLabel: "",
        dictType: "",
        dictSort: "",
        dictValue: "",
        isDefault: "Y",
        remark: "",
        listClass: "",
        dictNo: ''
      },
      title: "添加字典类型",
    };
  },
  methods: {
    async editDictData() {
      this.loading = true;
      await this.$api.systemConfig.editDictData(this.form).finally(() => {
        this.loading = false;
      });
      this.showDialog = false;
      this.$emit("getDictDataDataList");
    },
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
      } else {
        this.editForm && Object.assign(this.form, this.editForm);
        this.form.dictType = this.type
      }
    },
  },
};
</script>